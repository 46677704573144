const dataTags = [
  "Archéologie",
  "Astronomie",
  "Astrophysique",
  "Biochimie",
  "Chimie",
  "Climatologie",
  "Énergies",
  "Environnement",
  "Espace",
  "Génétique",
  "Géographie",
  "Géologie",
  "Géopolitique",
  "Histoire",
  "Informatique",
  "Ingénierie",
  "Linguistique",
  "Mathématique",
  "Médecine",
  "Neurosciences",
  "Paléontologie",
  "Philosophie",
  "Physique",
  "Politique",
  "Psychologie",
  "Santé",
  "Sociologie",
  "Zoologie",
];

export default dataTags;
